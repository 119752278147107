<template>
  <div class="app-wrapper">
    <div style="background: #0e1720;padding: 56px 0">
      <nav id="nav">
        <div class="nav-container" style="height: 3.5rem;top:0;">
          <div class="nav-content" style="top:0;">
            <button style="left: 0px; top: 0; margin: 0.5rem; position: absolute;" v-if="!loged_in" @click="login_as_test()" class="highlight-primary th">ทดลองเล่น</button>
            <img style="margin: 0.5rem" class="left-part icon-logo" :src="logo_url" />
            <button style="right: 0px; top: 0; margin: 0.5rem; position: absolute;" v-if="!loged_in" class="highlight-primary th" @click="nav_goto('/register')">เข้าสู่ระบบ</button>
            <button style="right: 0px; top: 0; margin: 0.5rem; position: absolute;" v-else class="highlight-primary th" @click="log_out()">ออกจากระบบ</button>
          </div>
        </div>
      </nav>
      <router-view 
        :withdraw="withdraw"
        :deposit="deposit"
        :username="username"
        :user_bank="user_bank"
        :credit="credit"
        @updateCredit="refresh_handler"/>

      <footer v-if="is_user">
        <div class="nav-container" style="height: 3.5rem;bottom: 0; justify-content: space-evenly;">
          <div @click="nav_goto('/profile')" class="nav-link">
            <img class="icon" :src="profile_icon" />
            <span>โปรไฟล์</span>
          </div>

          <div @click="nav_goto('/')" style="margin-bottom: 5%;" class="nav-link">
            <button id="game-icon">
              <img style="width: 100%" :src="game_icon" />
            </button>
            <span>เล่นเกม</span>
          </div>

          <div @click="nav_goto('/promotions')" class="nav-link">
            <img class="icon" :src="news_icon" />
            <span>โปรโมชั่น</span>
          </div>

        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import UserService from './services/UserService';
export default {
  name: 'App',
  data () {
    return {
      logo_url: require('@/assets/uday_logo.png'),
      game_icon: require('@/assets/ICON.png'),
      profile_icon: require('@/assets/profile.png'),
      news_icon: require('@/assets/news.png'),
      is_user: localStorage.getItem('user'),
      loged_in: localStorage.getItem('user') || localStorage.getItem('agent') || localStorage.getItem('staff') || localStorage.getItem('admin'),
      credit: 0,
      username: '',
      user_bank: '',
      deposit: [],
      withdraw: []
    }
  },
  methods: {
    update_credit(e) {
      this.credit = e
    },
    nav_goto(path) {
      try {
        this.$router.push(path)
        // document.getElementById('sign_in').click()
      } catch (err) {
        console.log(err);
      }
    },
    log_out() {
      localStorage.clear()
      location.reload()
    },
    async login_as_test() {
      const res = await UserService.login(`0999999999`, `test`)
      if (res) {
        location.reload()
      }
    },
    async refresh_handler() {
      const { username, balance, deposit, withdraw, bank } = await UserService.profile()
      this.credit = balance
      this.username = username
      this.user_bank = bank
      this.deposit = deposit
      this.withdraw = withdraw
    }
  },
  async mounted() {
    if (this.is_user) {
      this.refresh_handler()
    }
  }
}
</script>

<style lang="less">
h1, h3, h5 {
  color: #fde383 !important;
}
* {
  font-family: 'IBM Plex Sans Thai', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  .fadein {
    animation: fadein 1s ease 0s 1 normal forwards;
  }
    
  @keyframes fadein {
      0% {
          opacity: 0;
          transform: scale(1.4);
      }

      100% {
          opacity: 1;
          transform: scale(1);
      }
  }
  #game-icon {
    width: 70px;
    height: 70px;
    background-color: #1c2530;
    border: 3px solid #fce285;
    border-radius: 300%;
    margin: 0
  }
  body {
    background: none;
  }
  select {
    color: #fde383;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    background-color: #293444;
  }
  input {
    color: #fde383;
    padding: 0.75rem;
    width: 92%;
    border: none;
    border-radius: 5px;
    font-size: 20px;
  }
  label {
    font-size: 14px;
  }
  a {
    cursor: pointer;
  }
  footer {
    display: flex;
    justify-content: space-evenly;
  }
  button {
    height: 2.5rem;
    padding: 0.5rem;
    margin: 0.5rem;
    border: 1px solid #fce285;
    border-radius: 5px;
    cursor: pointer;
  }
  .icon-logo {
    height: 45px;
    cursor: pointer;
  }
  .background-body {
    background-color: #1c2530;
  }
  .background-panel {
    background-color: #293444;
  }
  .disabled {
    color: #fce285;
  }
  .highlight-primary {
    background-color: #293444;
  }
  .primary-title {
    color: #fce285
  }
  .highlight-secondary {
    background-color: #1c2530;
  }
  .app-wrapper {
    min-height: 100vh;
    max-width: 768px;
    margin: auto;
    text-align: left;
    color: #fde383;;
  }
  .app-wrapper::-webkit-scrollbar {
    display: none;
  }
  html, #app {
    background: url(https://static.vecteezy.com/system/resources/previews/002/490/560/non_2x/abstract-dark-blue-luxury-background-with-golden-line-diagonal-free-vector.jpg);
    user-select: none;
    background-size: cover;
    background-repeat: no-repeat;
    body {
      margin: 0;
    }
    ::-webkit-scrollbar {
      display: none
    }
  }
  .th {
    font-family: 'IBM Plex Sans Thai', sans-serif;
    color: #fce285
  }
  .nav-part {
    display: flex;
    align-items: center;

    padding: 0.5rem;
  }
  .nav-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    max-width: 768px;
    background-color: #18222c;
    box-shadow: 0 0 20px rgb(0 0 0);
    z-index: 12;

    .nav-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      margin-bottom: 5px;
      .icon {
        width: 25px;
        height: 100%;
        object-fit: cover;
        margin: 0.5rem;
        filter: invert(85%) sepia(32%) saturate(551%) hue-rotate(353deg) brightness(102%) contrast(98%);
      }
    }
  }
  #nav {
    width: 100%;
    a {
      font-weight: bold;
      color: #1c2530;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
