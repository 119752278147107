import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  /* User */
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'), /* Logged In Only */
  },
  {
    path: '/promotions',
    name: 'Promotion',
    component: () => import('../views/Promotions.vue') /* Promotion */
  },
  {
    path: '/game/joker',
    name: 'game-list-joker',
    component: () => import('../views/User/GameJoker.vue') /* Logged In Only */
  },
  {
    path: '/game/pg',
    name: 'game-list-pg',
    component: () => import('../views/User/GamePG.vue') /* Logged In Only */
  },
  {
    path: '/game/sg',
    name: 'game-list-sg',
    component: () => import('../views/User/GameSG.vue') /* Logged In Only */
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/User/RegisterUser.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/User/ProfileUser.vue')
  },
  /* Agents */
  {
    path: '/agent/login',
    name: 'Agent-login',
    component: () => import('../views/Agent/LoginAgent.vue')
  },
  {
    path: '/agent/dashboard',
    name: 'Agent-dashboard',
    component: () => import('../views/Agent/DashboardAgent.vue')
  },
  /* Staff */
  {
    path: '/staff/login',
    name: 'Staff-login',
    component: () => import('../views/Staff/LoginStaff.vue')
  },
  {
    path: '/staff/dashboard',
    name: 'Staff-dashboard',
    component: () => import('../views/Staff/DashboardStaff.vue')
  },
  /* Staff */
  {
    path: '/admin/login',
    name: 'Admin-login',
    component: () => import('../views/Admin/LoginAdmin.vue')
  },
  {
    path: '/admin/dashboard',
    name: 'Admin-dashboard',
    component: () => import('../views/Admin/NewDashboardAdmin.vue')
  },
  {
    path: '/admin/main',
    name: 'Admin-main',
    component: () => import('../views/Admin/DashboardAdmin.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
