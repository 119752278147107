import httpClient from './httpClient';
const prefix = '/player'

export default {
  async login(username, password) {
      const { data: token } = await httpClient({ requiresAuth: false }).post('/login-user', { phone_number: username, password: password})
      if (token) {
        localStorage.setItem('user', token.token)
        return true
      } else {
        return false
      }
  },
  async register({ line_id, bank, username, phone_number, password, agent_id }) {
    const { data: { token } } = await httpClient({ requiresAuth: false }).post('/register-user',{ line_id, bank, username, phone_number, password, agent_id });
    if (token) {
      localStorage.setItem('user', token.token)
      return true
    } else {
      return false
    }
  },
  async profile () {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'user' }).get(prefix+'/profile')

    return data
  },
  async get_staff() {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'user' }).get(prefix+'/get-staff')

    return data
  },
  async deposit(amount) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'user' }).post(prefix+'/deposit', { amount })

    return data
  },
  async withdraw(amount) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'user' }).post(prefix+'/withdraw', { amount })

    return data
  },
}